@import "../../styles/variables.scss";

.xgs-arrive-at-stop {
  &__dialog-content {
    min-height: 196px;
  }

  &__header {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    strong {
      font-weight: 600;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    &--back.xgs-btn {
      width: 140px;
    }

    &--reached.xgs-btn {
      width: 100%;
    }

    img {
      margin-right: 12px;
    }
  }
}