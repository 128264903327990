@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.xgs-slide-in-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 200;
  transition: background-color 0.5s ease-in-out;
  pointer-events: none;
  overflow: auto;

  &--opened {
    // pointer-events: all;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &--closed {
    background-color: rgba(0, 0, 0, 0);
  }

  &__content {
    position: fixed;
    height: 100%;
    min-width: 50vw;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 201;
    border-radius: $border-radius 1px 1px $border-radius;
    transition: transform 0.5s ease-in-out;

    @include mobile {
      border-radius: $border-radius $border-radius 1px 1px;
      height: auto;
      min-height: 40vh;
      width: 100%;
    }

    @include tablet {
      border-radius: $border-radius $border-radius 1px 1px;
      height: auto;
      min-height: 40vh;
      width: 100%;
    }

    &--opened {
      transform: translateX(0%);

      @include mobile {
        transform: translateY(0%);
      }

      @include tablet {
        transform: translateY(0%);
      }
    }

    &--closed {
      transform: translateX(100%);

      @include mobile {
        transform: translateY(100%);
      }

      @include tablet {
        transform: translateY(100%);
      }
    }

    &--full-screen {
      @include tablet {
        height: 100%;
      }
      @include mobile {
        height: 100%;
      }
      height: 100%;
    }
  }

  &__header {
    display: flex;
    padding: 0px 16px;
    height: 60px;
    border-bottom: 1px solid $gray4;
    justify-content: space-between;
    align-items: center;
  }

  &__body-scroll {
    overflow: auto;
    width: 100%;
    height: calc(100% - 60px);
  }

  &__body {
    padding: $margin6 32px;
    max-width: 640px;
    margin: auto;
    overflow: auto;
  }
}


.slide-header {
  display: flex;
  padding: 0px 15px;
  height: 60px;
  border-bottom: 1px solid $gray4;
  justify-content: space-between;
  align-items: center;
}

.slide-body {
  padding: $margin7;
}
