@import "./../../styles/variables.scss";

$tag-opacity: 0.16;

.tag {
  display: inline-block;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto-Medium";
  line-height: 16px;
  height: 20px;
  border-radius: $border-radius;
  width: fit-content;
  white-space: nowrap;

  &--color {
    &_red {
      color: #C43232;
      background-color: rgba(221, 63, 63, $tag-opacity);
    }

    &_green {
      color: #14870A;
      background-color: rgba(93, 199, 84, $tag-opacity);
    }

    &_yellow {
      color: #B89311;
      background-color: rgba(232, 209, 90, $tag-opacity);
    }

    &_orange {
      color: #E16C00;
      background-color: rgba(225, 108, 0, $tag-opacity);
    }

    &_grey {
      color: $gray8;
      background-color: rgba($gray8, $tag-opacity);
    }

    &_blue {
      color: #2F80ED;
      background-color: rgba(47, 128, 237,$tag-opacity);
    }

    &_purple {
      color: $purple1;
      background-color: rgba($purple1, $tag-opacity);
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--clickable {
    cursor: pointer;
  }
}