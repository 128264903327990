@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.xgs-upload-bol {
  margin-bottom: $margin6;
  margin-top: $margin6;

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: $margin8;
  }

  &__notes {
    font-size: 14px;   
    margin-bottom: $margin7;
  }

  &__upload-buttons {
    display: flex;
    margin-bottom: $margin7;
    gap: 16px;
    height: fit-content;

    &__scan {
      width: 170px;
    }
  }

  &__photos {
    margin-bottom: $margin5;

    &__heading {
      margin-bottom: $margin7;
      font-size: 16px;
      font-weight: 600;
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    &__none-selected {
      font-size: 14px;
    }

    button {
      width: 100%;
      height: 24px;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
    }

    &__item {
      display: flex;
      gap: 12px;
      flex-direction: column;
      position: relative;
      width: 132px;
      min-height: 160px;
      &__name {
        font-size: 12px;
      }
  
      &__thumbnail {
        height: 100%;
        max-height: 182px;
        padding: 8px;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid rgba(79, 95, 114, 0.32);
        background: rgba(79, 95, 114, 0.10);

      }

      &__zoom {
        position: absolute;
        left: 50%;
        cursor: pointer;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__scanner-app {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: $margin7;
    button {
      width: 50%;
    }

    &__loading {
      display: flex;
      align-items: center;
      height: 40px;

      svg {
        height: 32px;
        margin-right: 10px;
      }
    }
  }

  &__error {
    color: $red;
    margin-bottom: $margin8;
  }
}
