@import "../../styles/variables.scss";

.xgs-notify-consignee {
  &__title {
    font-size: 24px;
    margin-bottom: $margin6;
    font-weight: 600;
    margin-top: $margin8;
  }

  &__loading {
    text-align: center;
    margin-top: 30px;
  }

  &__info {
    margin-bottom: $margin6;
  }
  
  &__radio {
    display: block;
    margin-bottom: $margin7;
    input {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }

  &__submit {
    display: flex;
    width: 100%;
    gap: 16px;
    button {
      width: 100%;
    }
    margin-top: 30px;
    margin-bottom: 30px;
  }
}