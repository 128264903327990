@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.xgs-complete-pickup {
  cursor: auto;

  &__submit {
    margin-top: $margin8;
    width: 200px;
    @include up-to(601px) {
      width: 100%;
    }
  }

  &__form {
    &__upload {
      margin-bottom: 16px;
  
      &__header {
        font-size: 14px;
      }
    }
  
    &__photos {
      max-width: 540px;
      max-height: 280px;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 8px;
  
      &__item {
        border: 1px solid $gray6;
        margin: 0 8px 16px 8px;
  
        &--deleted {
          display: none;
        }
  
        &__image {
          position: relative;
          display: flex;
          height: 160px;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          
          img {
            display: block;
            max-width: 100%;
            height: auto;
            flex-grow: 0;
            flex-shrink: 0;
          }
  
          &__control {
            position: absolute;
            top: 4px;
            right: 4px;
            background-color: $gray4;
            padding: 2px 6px;
            color: $red;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__buttons {
    margin: 24px 0 32px 0;

    .xgs-btn {
      @include up-to(601px) {
        width: 100%;
      }
    }
  }

  &__error {
    max-width: 600px;
    margin-top: 16px;
  }

  &__probill-input {
    width: 200px;
    margin-bottom: $margin8;
  }

  &__special {
    font-size: 14px;
  }
}