/*colors*/
$gray0: #000000;
$gray1: #333333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #BDBDBD;
$gray5: #E0E0E0;
$gray6: #efefef;
$gray7: #f8f8f8;
$gray8: #4F5F72;
$blue1: #2F80ED;
$blue2: #2D9CDB;
$blue3: #56CCF2;
$blue4: #00C1FF;
$lightblue: #ddebff;
$darkblue1: #424D73;
$green1: #219653;
$green2: #c3f7c8;
$green3: #6FCF97;
$green4: #ABFEB4;
$purple1: #7936D0;
$purple2: #5613AE;
$purple3: #DEC4FF;
$purple4: #EADAFF;
$purple5: #F7F0FF;
$purple6: #FBF8FF;
$purple7: #F2E9FF;
$red: #cc0000;
$red2: #922D30;
$orange: #F2994A;
$yellow: #F2C94C;
$lightred1: #c74040;
$lightred2: #ffe6e6;
$green-button: #33bf6e;
$red-button: #E82A2B;

$gray_8: rgba(79, 95, 114, 0.08);
$gray_32: rgba(79, 95, 114, 0.32);
$gray_72: rgba(79, 95, 114, 0.72);

$supporting_blue_8: rgba($blue1, 0.08);

/*margins*/
$margin1: 64px;
$margin2: 56px;
$margin3: 48px;
$margin4: 40px;
$margin5: 32px;
$margin6: 24px;
$margin7: 16px;
$margin8: 8px;

/*header*/ 
$header-back: #ffffff;
$header-border-bottom-color: $gray5;
$header-height: 82px;
$header-text-color: #696969;

/*side menu*/
$side-menu-back: $gray5;
$side-menu-item-back: $side-menu-back;
$side-menu-item-hover-back: $side-menu-back;
$side-menu-item-selected-back: $side-menu-back;
$side-menu-item-selected-color: $blue1;
$side-menu-item-color: $gray1;
$side-menu-width-desktop: 200px;
$side-menu-width-tablet: 250px;
$side-menu-width-mobile: 100vw;

/*form*/
$form-validation-error-color: $red;

/*content*/
$content-page-loading-background: $gray6;
$height-without-header: calc(100vh - #{$header-height});

/*table*/
$table-row-height: $margin4;

/*common*/
$form-full-width: 540px;
$mobile-width: 768px;
$tablet-width: 1024px;
$link-color: $gray2;

:export {
  headerHeight: $header-height;
  bottomPadding: $margin7;
  tableRowHeight: $table-row-height;

  red: $red;
  orange: $orange;
  green1: $green1;
  green3: $green3;
  blue2: $blue2;

  gray0: $gray0;
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  gray7: $gray7;
}

$border-radius: 4px;
$tablet-width-up-to: 601px;
