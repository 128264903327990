@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-exceptions {
  max-width: 900px;
  margin: 0 8px;
  font-weight: normal;

  &__button {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 4px;
    }

    &__text {
      max-width: 28px;
      overflow: hidden;
    }
  }

  &__form {
    &__label {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 3px;
    }

    &__upload {
      margin-bottom: 16px;

      &__header {
        font-size: 14px;
      }
    }

    &__photos {
      max-width: 540px;
      max-height: 280px;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 8px;

      &__item {
        border: 1px solid $gray6;
        margin: 0 8px 16px 8px;

        &--deleted {
          display: none;
        }

        &__image {
          position: relative;
          display: flex;
          width: 240px;
          height: 140px;
          overflow: hidden;
          justify-content: center;
          align-items: center;

          img {
            display: block;
            max-width: 100%;
            height: auto;
            flex-grow: 0;
            flex-shrink: 0;
          }

          &__control {
            position: absolute;
            top: 4px;
            right: 4px;
            background-color: $gray4;
            padding: 2px 6px;
            color: $red;
            cursor: pointer;
          }
        }
      }
    }

    &__textarea {
      resize: none;
    }

    &__buttons {
      margin: 24px 0 32px 0;

      .xgs-btn {
        @include up-to(601px) {
          width: 100%;
        }
      }
    }

    &__error {
      max-width: 600px;
      margin-top: 16px;
    }

    .xgs-radio__group {
      margin: 2px 0 16px 0;

      @include up-to(600px) {
        justify-content: flex-start;
      }

      &__item {
        flex-grow: 1;
        max-width: 32%;

        @include up-to(520px) {
          width: 100%;
          max-width: none;
        }
      }
    }
  }

  &__details {
    &__photos {
      padding: 6px 0;
      font-size: 14px;
      font-weight: bold;
      color: $gray2;
    }
  }
}

.xgs-stop-details__roll .xgs-exceptions__button,
.xgs-probill-accordion__button .xgs-exceptions__button {
  position: absolute;
  right: 20px;
  height: fit-content;
  width: fit-content;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 6px;
}

.xgs-consignee-accordion__button .xgs-exceptions__button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  font-size: 18px;

  &__icon {
    display: none;
  }

  &__text {
    max-width: none;
  }
}
