@import "../../styles/variables.scss";

.xgs-preview-image {
  background-color: black;
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 80%;
  }

  &__control {
    position: absolute;
    top: 12%;
    right: 30%;
    background-color: $gray4;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}